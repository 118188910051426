import { combineReducers } from 'redux';

import { DashboardReducer } from '../containers/Dashboard/reducer';
import { IdentityDetailsReducer } from '../containers/MyProfile/reducer';
import { ResetPasswordReducer } from '../containers/ResetPassword/reducer';
import { StartupsReducer } from '../containers/Startups/reducer';
import { AuthReducer } from '../containers/App/reducer';
import { KycStatusReducer } from '../containers/KycStatus/reducer';
import { KycSubmitReducer } from '../containers/KycSubmitDocuments/reducer';
import { DocumentsReducer } from '../containers/Documents/reducer';
import { StartupPageReducer } from '../containers/StartupPage/reducer';
import { NdaPageReducer } from '../containers/Nda/reducer';
import { IdentityRegisterReducer } from '../containers/SignUpModel/reducer';
import { AccreditationReducer } from '../containers/AccreditationForm/reducer';
import { AccreditationAffirmationReducer } from '../containers/Affirmation/reducer';
import { InvestmentReducer } from '../containers/Invest/reducer';
import { InvestmentDataReducer } from '../containers/Kyc/reducer';
import { LayoutReducer } from '../containers/Layouts/MainLayout/reducer';
import { emailVerificationReducer } from '../containers/EmailVerification/reducer';
import { FounderPageReducer } from '../containers/FounderPage/reducer';
import { DocumentsUploaderReducer } from '../containers/DocumentsUploader/reducer';
import { ImpersonateReducer } from '../containers/Impersonate/reducer';

const rootReducer = combineReducers({
  dashboard: DashboardReducer,
  identityDetails: IdentityDetailsReducer,
  resetPassword: ResetPasswordReducer,
  startups: StartupsReducer,
  auth: AuthReducer,
  kycStatus: KycStatusReducer,
  kycSubmit: KycSubmitReducer,
  documents: DocumentsReducer,
  startupPage: StartupPageReducer,
  ndaPage: NdaPageReducer,
  identityRegister: IdentityRegisterReducer,
  accreditation: AccreditationReducer,
  accreditationAffirmation: AccreditationAffirmationReducer,
  investment: InvestmentReducer,
  investmentData: InvestmentDataReducer,
  layout: LayoutReducer,
  emailVerification: emailVerificationReducer,
  founderPage: FounderPageReducer,
  documentsUploader: DocumentsUploaderReducer,
  impersonate: ImpersonateReducer,
});

export default rootReducer;
