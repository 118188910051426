import React, { useEffect, useLayoutEffect } from 'react';

//third party libs
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//Layouts
import EmptyLayout from '../Layouts/EmptyLayout';
import MainLayout from '../Layouts/MainLayout';

// containers and components
import InvestmentOpportunities from '../InvestmentOpportunities';
import ErrorFallback from '../../components/ErrorFallback';
import { PAGE_TITLES } from '../../helpers/constants';
import AccreditationForm from '../AccreditationForm';
import InvestESign from '../Invest/InvestESign';
import ResetPassword from '../ResetPassword';
import Accreditation from '../Accreditation';
import ThankYouPage from '../ThankYouPage';
import StartupPage from '../StartupPage';
import AffirmationDenied from '../Affirmation/AffirmationDenied';
import FounderPage from '../FounderPage';
import FounderSubmitted from '../FounderPage/FounderSubmitted';
import UploaderSubmitted from '../DocumentsUploader/UploaderSubmitted';
import Impersonate from '../Impersonate';
import EmailVerification from '../EmailVerification';
import Dashboard from '../Dashboard';
import MyProfile from '../MyProfile';
import KycStatus from '../KycStatus';
import Documents from '../Documents';
import LoginPage from '../LoginPage';
import Startups from '../Startups';
import Invest from '../Invest';
import Nda from '../Nda';
import NdaThank from '../Nda/Thank';
import Kyc from '../Kyc';
import DocumentsUploader from '../DocumentsUploader';

import { refreshTraitsAttempt } from './actions';

import { track, init, time } from '../../services/analytics';

const App = (props) => {
  // hooks
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (document.readyState === 'complete') {
      track('Page Change', { url: pathname });
      time('Page Change');
      dispatch(refreshTraitsAttempt({}));
    }
  }, [pathname, dispatch]);

  useEffect(() => {
    if (document.readyState === 'complete') init();
  });

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <MainLayout title={PAGE_TITLES.dashboard} lockGuard={true} navigateTo="dashboard" hasNext={true} track="home">
            <LoginPage />
          </MainLayout>
        }
      />
      <Route
        exact
        path="/sso/signin"
        element={
          <MainLayout title={PAGE_TITLES.dashboard} lockGuard={true} navigateTo="dashboard" hasNext={true} track="home">
            <LoginPage />
          </MainLayout>
        }
      />
      <Route
        exact
        path="/sso/signup"
        element={
          <MainLayout title={PAGE_TITLES.dashboard} lockGuard={true} navigateTo="dashboard" hasNext={true} track="home" isSignup={true}>
            <LoginPage />
          </MainLayout>
        }
      />
      <Route
        path="/login"
        element={
          <EmptyLayout title={PAGE_TITLES.Login} lockGuard={true} navigateTo="dashboard" hasNext={true} track="signin">
            <LoginPage />
          </EmptyLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <MainLayout
            title={PAGE_TITLES.dashboard}
            lockGuard={true}
            navigateTo="dashboard"
            hasNext={false}
            track="dashboard"
          >
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/investment-opportunities"
        element={
          <MainLayout
            title={PAGE_TITLES.investmentOpportunities}
            lockGuard={true}
            navigateTo="investment-opportunities"
            hasNext={false}
            track="investment-opportunities"
          >
            <InvestmentOpportunities />
          </MainLayout>
        }
      />
      <Route
        path="/profile"
        element={
          <MainLayout title={PAGE_TITLES.profile} lockGuard={true} navigateTo="profile" hasNext={false} track="profile">
            <MyProfile />
          </MainLayout>
        }
      />
      <Route
        path="/change-password"
        element={
          <MainLayout
            title={PAGE_TITLES.changePassword}
            lockGuard={false}
            navigateTo="dashboard"
            hasNext={false}
            track="change-password"
          >
            <ResetPassword />
          </MainLayout>
        }
      />
      <Route
        path="/founder"
        element={
          <MainLayout
            title={PAGE_TITLES.founder}
            lockGuard={false}
            navigateTo="founder"
            hasNext={false}
            track="founder-show"
          >
            <FounderPage />
          </MainLayout>
        }
      />
      <Route
        path="/foundersubmitted"
        element={
          <MainLayout
            title={PAGE_TITLES.founder}
            lockGuard={false}
            navigateTo="foundersubmitted"
            hasNext={false}
            track="founder-thank-you"
          >
            <FounderSubmitted />
          </MainLayout>
        }
      />
      <Route
        path="/startups"
        element={
          <MainLayout
            title={PAGE_TITLES.startups}
            navigateTo="startups"
            lockGuard={false}
            hasNext={false}
            track="funded-opportunities"
          >
            <Startups />
          </MainLayout>
        }
      />
      <Route
        path="/browse/startups"
        element={
          <MainLayout
            title={PAGE_TITLES.startups}
            navigateTo="startups"
            lockGuard={false}
            hasNext={false}
            track="funded-opportunities"
          >
            <Startups />
          </MainLayout>
        }
      />
      {/* no need for title here, we use slug */}
      <Route
        path="/startup/:slug"
        element={
          <MainLayout navigateTo="startup" lockGuard={false} hasNext={false} track="startup.memo">
            <StartupPage />
          </MainLayout>
        }
      />
      <Route
        path="/startup/:slug/snapshot"
        element={
          <MainLayout navigateTo="startup" lockGuard={false} hasNext={false} track="startup.memo">
            <StartupPage />
          </MainLayout>
        }
      />
      <Route
        path="/child-startup/:slug"
        element={
          <MainLayout navigateTo="child-startup" lockGuard={false} hasNext={false}>
            <StartupPage isChild={true} />
          </MainLayout>
        }
      />
      <Route
        path="/startup/:slug/nda/thank-you"
        element={
          <MainLayout lockGuard={true} navigateTo="dashboard" hasNext={false} track="startup.nda">
            <NdaThank />
          </MainLayout>
        }
      />
      <Route
        path="/startup/:slug/nda"
        element={
          <MainLayout lockGuard={true} navigateTo="dashboard" hasNext={false} track="startup.nda">
            <Nda />
          </MainLayout>
        }
      />
      <Route
        path="/invest/:currentRoundId/entity/:amount"
        element={
          <MainLayout title={PAGE_TITLES.invest} lockGuard={true} navigateTo="dashboard" hasNext={false} track="entity">
            <Invest />
          </MainLayout>
        }
      />
      <Route
        path="/invest/esign/:investmentType/:investmentId"
        element={
          <MainLayout title={PAGE_TITLES.invest} lockGuard={true} navigateTo="dashboard" hasNext={false} track="esign">
            <InvestESign />
          </MainLayout>
        }
      />
      <Route
        path="/invest/:investmentType/:investmentId/kyc"
        element={
          <MainLayout title={PAGE_TITLES.invest}>
            <Kyc />
          </MainLayout>
        }
      />
      <Route
        path="/invest/:investmentType/:investmentId/thank-you"
        element={
          <MainLayout title={PAGE_TITLES.thankYou} lockGuard={true} navigateTo="dashboard" hasNext={false}>
            <ThankYouPage />
          </MainLayout>
        }
      />
      <Route
        path="/entity/upload/:id"
        element={
          <MainLayout lockGuard={true} navigateTo="dashboard" hasNext={false} track="upload-forms">
            <Kyc />
          </MainLayout>
        }
      />
      <Route
        path="/entity/verification"
        element={
          <MainLayout lockGuard={true} navigateTo="entity/verification" hasNext={false} track="account-verification">
            <KycStatus />
          </MainLayout>
        }
      />
      <Route
        path="/documents"
        element={
          <MainLayout
            title={PAGE_TITLES.documents}
            lockGuard={true}
            navigateTo="documents"
            hasNext={false}
            track="documents"
          >
            <Documents />
          </MainLayout>
        }
      />
      <Route
        path="/user/not-accredited"
        element={
          <MainLayout navigateTo="dashboard" lockGuard={false} hasNext={false}>
            <AffirmationDenied />
          </MainLayout>
        }
      />
      <Route
        path="/error"
        element={
          <MainLayout title={PAGE_TITLES.error} track="error">
            <ErrorFallback />
          </MainLayout>
        }
      />
      <Route
        path="/accreditation/:countryCode"
        element={
          <EmptyLayout
            whiteBg={true}
            title={PAGE_TITLES.accreditation}
            lockGuard={true}
            navigateTo="dashboard"
            hasNext={false}
          >
            <Accreditation />
          </EmptyLayout>
        }
      />
      <Route
        path="/accreditation"
        element={
          <MainLayout
            title={PAGE_TITLES.accreditation}
            lockGuard={true}
            navigateTo="accreditation"
            hasNext={false}
            track="update-accreditation"
          >
            <AccreditationForm />
          </MainLayout>
        }
      />

      <Route
        path="/uploader"
        element={
          <EmptyLayout
            title={PAGE_TITLES.uploader}
            lockGuard={true}
            hasNext={true}
            navigateTo="uploader"
            whiteBg={true}
          >
            <DocumentsUploader />
          </EmptyLayout>
        }
      />
      <Route
        path="/uploadersubmitted"
        element={
          <EmptyLayout
            title={PAGE_TITLES.uploader}
            lockGuard={false}
            navigateTo="uploadersubmitted"
            hasNext={false}
            whiteBg={true}
          >
            <UploaderSubmitted />
          </EmptyLayout>
        }
      />
      <Route
        path="/imper"
        element={
          <MainLayout lockGuard={false} navigateTo="dashboard" hasNext={false}>
            <Impersonate />
          </MainLayout>
        }
      />
      <Route
        path="/email-verification"
        element={
          <MainLayout lockGuard={false} navigateTo="dashboard" hasNext={false}>
            <EmailVerification />
          </MainLayout>
        }
      />
      <Route
        path="*"
        element={
          <MainLayout track="error">
            <ErrorFallback />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default App;
