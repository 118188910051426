import React from 'react';
import Text from '../text';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { useNavigate } from 'react-router-dom';

import { getCloudinaryLogo } from '../../helpers/cloudinary';

import IsMobileDevice from '../Generic/IsMobileDevice';

const Cell = ({ index, name, sector, funding, url, startupSlug, widthClass }) => {
  const navigate = useNavigate();
  const isMobile = IsMobileDevice();

  return (
    <TableCell key={index} className={`f-rows-d ${widthClass}`}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isMobile ? null : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={getCloudinaryLogo(url, 45)}
              alt="company logo"
              className="table-company-img h-w-45 pointer"
              onClick={startupSlug ? () => navigate(`/startup/${startupSlug}`) : null}
            />
          </div>
        )}
        <div>
          <Link href={startupSlug ? `/startup/${startupSlug}` : null}>
            <Text classes={`${startupSlug ? 'table-company-label' : ''} f-f-g f-w-300 f-size-14`} text={name} />
          </Link>
          <div className="toPdf">
            <br className="hidden" />
          </div>
          <Text classes="table-company-sector capitalize" style={{ textTransform: 'capitalize' }} text={sector} />
          {funding ? (
            <Button variant="contained" className="btn invest-btn f-f-gl f-w-700 f-size-13 mt-5 g3-c" fullWidth>
              Invest
            </Button>
          ) : null}
        </div>
      </div>
    </TableCell>
  );
};

export default Cell;
