import React, { useCallback, useEffect, useState } from 'react';

// mui utils
import DialogContent from '@mui/material/DialogContent';
import MobileStepper from '@mui/material/MobileStepper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

// iAngels utils
import { EMAIL_MAX_LENGTH } from '../../helpers/constants';
import { track, identify } from '../../services/analytics';
import LoadingIndicator from '../../components/Generic/LoadingIndicator';
import { identifyUserAttempt, clearUserState } from '../App/actions';
import { identityRegisterAttempt, clearSignup } from './actions';
import ErrorAlert from '../../components/Generic/ErrorAlert';
import IsMobileDevice from '../../components/Generic/IsMobileDevice';

import { getUserSelector } from '../App/selectors';
import RegisterComplete from './RegisterComplete';
import { getOwner } from '../../helpers/iangels';
import RegisterForm from './RegisterForm';

// third party utils
import { useSelector, useDispatch } from 'react-redux';
import 'react-phone-input-2/lib/material.css';
import TagManager from 'react-gtm-module';
import _ from 'lodash';

import {
  getErrorSelector,
  getIdentityRegisterAttemptingSelector,
  getIdentityRegisterSuccessSelector,
} from './selectors';

// json
import countries from '../../components/SharedData/countries.json';

const SignUpModel = () => {
  // hooks
  const isMobile = IsMobileDevice();
  const dispatch = useDispatch();

  // selectors
  const registerSuccess = useSelector(getIdentityRegisterSuccessSelector);
  const loading = useSelector(getIdentityRegisterAttemptingSelector);
  const error = useSelector(getErrorSelector);
  const user = useSelector(getUserSelector);

  // state
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(true);
  const [phone, setPhone] = useState('');

  var firstName = '';
  var lastName = '';
  const fullName = /^[A-Za-z0-9\-_ ()/."\\]*$/.test(user.user_metadata.full_name) ? user.user_metadata.full_name : '';

  if (!(fullName === '')) {
    if (/ /.test(fullName)) {
      firstName = fullName.split(' ', 1)[0];
    } else {
      firstName = fullName;
    }
  } else {
    firstName = '';
  }

  lastName = /\s/.test(fullName) ? fullName.substr(firstName.length + 1) : '';

  useEffect(() => {
    if (registerSuccess) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [registerSuccess, dispatch]);

  useEffect(() => {
    if (error) {
      setHasError(true);
      dispatch(clearSignup());
    }
  }, [error, dispatch]);

  const handleExplore = () => {
    setOpen(false);
    dispatch(clearSignup());
    dispatch(clearUserState());
    dispatch(identifyUserAttempt());
  };

  const initialState = useCallback(
    () => ({
      firstName: firstName || '',
      lastName: lastName || '',
      city: '',
      state: '',
      country: _.find(countries, { code: 'IL' }) || '',
      countryCode: 'IL',
      accredited: 'true',
      allowCommunication: null,
      email: user?.email,
      stateCode: '',
    }),
    [user, firstName, lastName]
  );

  const [registerFormValues, setRegisterFormValues] = useState(initialState);

  useEffect(() => {
    setRegisterFormValues(initialState);
  }, [user, initialState]);

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    country: '',
    countryCode: '',
    termsAndConditions: '',
    terms: '',
    allowCommunication: '',
  });

  const validForm = () => {
    let newErrors = { ...errors };

    const firstNameValid = isValid(
      registerFormValues.firstName,
      (message) => (newErrors.firstName = message),
      'First name'
    );
    const lastNameValid = isValid(
      registerFormValues.lastName,
      (message) => (newErrors.lastName = message),
      'Last name'
    );

    let phoneValid = isPhoneValid(phone.replace(/[- )(]/g, ''), newErrors);

    setErrors(newErrors);

    newErrors.terms = !termsAndConditions ? 'please accept the terms and conditions to proceed.' : '';
    setErrors(newErrors);

    newErrors.allowCommunication = registerFormValues.allowCommunication == null ? 'This field is required.' : '';
    setErrors(newErrors);
    if (
      !firstNameValid ||
      !lastNameValid ||
      !phoneValid ||
      !termsAndConditions ||
      registerFormValues.allowCommunication == null
    )
      return false;

    newErrors.terms = '';
    return true;
  };
  const handleNext = () => {
    try {
      if (!validForm()) {
        track('Personal Details Validation Error', {
          category: 'Onboarding',
          // invalid_details: _.concat(invalidFields, self.getMissingDetails()) NEED TO IMPLEMENT THIS
        });
        return;
      }
      track('User Started Registration', { category: 'Onboarding' });
      let data = JSON.parse(JSON.stringify(registerFormValues));
      data.country = registerFormValues.countryCode ? registerFormValues.country.name : '';
      data.fullName = registerFormValues.firstName.trim() + ' ' + registerFormValues.lastName.trim();
      data.phone = phone.replace(/[- )(]/g, '');
      data.roles = ['investor'];
      data.owner = getOwner(registerFormValues.country?.name, registerFormValues.stateCode);
      delete data.firstName;
      delete data.lastName;
      delete data.state;

      if (data.accredited === 'true') {
        track('User Accredited', { category: 'Onboarding' });
        TagManager.dataLayer({ dataLayer: { event: 'user_accredited' } });
      } else {
        track('User Non-Accredited', { category: 'Onboarding' });
      }

      if (data.allowCommunication) {
        track('User Subscribed', { category: 'Onboarding' });
      }
      track('Personal Details Completed', { category: 'Onboarding' });
      track('User Registered', { category: 'Onboarding' });
      dispatch(identityRegisterAttempt(data));
    } catch (e) {
      track('User Registration Error', { category: 'Onboarding' });
    }
    identify(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isPhoneValid = (number, newErrors) => {
    if (number.length < 7) {
      newErrors.phone = 'Phone number is required.';
      return false;
    }
    newErrors.phone = '';
    return true;
  };

  const isValid = (text, callback, fieldName) => {
    if (text.length === 0) {
      callback(`${fieldName} is required.`);
      return false;
    }

    if (text.trim().length > EMAIL_MAX_LENGTH) {
      callback(`${fieldName} is too long.`);
      return false;
    }

    if (!/^[A-Za-z0-9\-_ ()/."\\]*$/.test(text)) {
      callback(`Only English and special characters are allowed.`);
      return false;
    }

    callback('');
    return true;
  };

  const handleBackOfficeClick = () => {
    window.location.href = 'mailto:backoffice@iangels.com';
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="900"
      scroll="paper"
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        <MobileStepper
          variant="progress"
          steps={3}
          position="static"
          activeStep={activeStep}
          style={{ width: isMobile ? 350 : 600 }}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="stepper"
          nextButton={<Button size="small">Finish</Button>}
          backButton={
            <Button size="small" onClick={handleBack}>
              Start
            </Button>
          }
        />
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        {loading ? (
          <LoadingIndicator />
        ) : activeStep === 1 ? (
          <div className="df-c fdr-c">
            <ErrorAlert
              style={{ maxWidth: 800 }}
              hasError={hasError}
              setHasError={setHasError}
              title="Something went wrong"
              text="Something went wrong and we couldn't complete your registration. Please contact our compliance officer at "
              extraText="backoffice@iangels.com"
              handleClick={handleBackOfficeClick}
              extraClasses="bold pointer"
            />
            <RegisterForm
              setValues={setRegisterFormValues}
              values={registerFormValues}
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              errors={errors}
              setErrors={setErrors}
              setPhone={setPhone}
              phone={phone}
            />
          </div>
        ) : (
          <RegisterComplete />
        )}

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: isMobile ? 20 : 0 }}>
          {!loading && (
            <Button
              variant="contained"
              className="btn form-btn"
              style={{
                minWidth: isMobile ? '100%' : 100,
                minHeight: 50,
                borderRadius: 0,
                margin: isMobile ? 0 : 20,
                maxWidth: 250,
                alignSelf: 'center',
              }}
              onClick={activeStep === 1 ? handleNext : handleExplore}
            >
              {activeStep === 1 ? 'Next' : 'Explore'}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpModel;
